<template>
  <div>
    <bread-crumb :navigationArray="navigationLink"/>
    <div class="white-container search">
      <v-input-search class="search__input"
                      :tags="tagSearch"
                      :placeholder="GET_LANG ? 'Введите слово для поиска' : 'Enter a search word'"/>
      <p class="search__subtitle">
        {{ `${GET_LANG ? 'Найдено' : 'Found'} ${$store.getters.getSearchMembers.length + $store.getters.getSearchNews.length + $store.getters.getRegionalNews.length + $store.getters.getComment.length + $store.getters.getBlogs.length }  ${GET_LANG ? 'совпадений' : 'coincidences'}`}}
      </p>
      <main>
        <div class="search__content">
          <transition name="fade">
            <loader v-if="getLoader"/>
          </transition>

          <router-link
              v-for="item in $store.getters.getSearchMembers"
              :to="`/member_profile/${item.id}`"
              :key="`searchMembers${item.id}`">
              <div class="search__wrapper-flex">
                <img class="search__photo" :src="`${$store.getters.getUrlApi}${item.photo}`" alt="">
                  <p class="search__title">{{ GET_LANG ? item.full_name : item.full_name_eng }}</p>
              </div>
            </router-link>
          <router-link
            v-for="item in $store.getters.getSearchAnnouncement"
            :to="`/announcement/${item.id}`"
            :key="`searchAnnouncement${item.id}`">
            <div>
              <div class="tag-list">
                <p v-for="(tag, i) in (GET_LANG ? item.tags_list : item.tags_list_eng)" :key="`item.tags_list${tag}${i}`" >{{ tag }}</p>
              </div>
              <div class="search__wrapper-flex search-news">
                <img class="search__img" :src="item.preview_photo" alt="">
                <div>
                  <p class="search__date">
                    {{ getFormattedDate(new Date(item.date), true, true) }}
                  </p>
                  <p class="search__title">{{ GET_LANG ? item.title : item.title_eng }}</p>
                  <p class="search__text">{{ GET_LANG ? item.description : item.description_eng }}</p>
                </div>
              </div>
            </div>
          </router-link>
          <router-link
            v-for="item in $store.getters.getSearchNews"
            :to="`/news/${item.slug}`"
            :key="`searchNews${item.id}`">
            <div>
              <div class="tag-list">
                <p v-for="(tag, i) in (GET_LANG ? item.tags_list : item.tags_list_eng)" :key="`item.tags_list${tag}${i}`" >{{ tag }}</p>
              </div>
              <div class="search__wrapper-flex search-news">
                <img class="search__img" :src="item.preview_photo" alt="">
                <div>
                  <p class="search__date">
                    {{ getFormattedDate(new Date(item.date_time)) }}
                  </p>
                  <p class="search__title">{{ GET_LANG ? item.title : item.title_eng }}</p>
                  <p class="search__text">{{ GET_LANG ? item.description : item.description_eng }}</p>
                </div>
              </div>
            </div>
          </router-link>
          <router-link
            v-for="(item, i) in $store.getters.getRegionalNews"
            :to="`/news/${item.id}`"
            :key="`getRegionalNews${i}`">
            <div>
              <div class="tag-list">
                <p v-for="(tag, i) in (GET_LANG ? item.tags_list : item.tags_list_eng)" :key="`item.tags_list${tag}${i}`" >{{ tag }}</p>
              </div>
              <div class="search__wrapper-flex search-news">
                <img class="search__img" :src="item.preview_photo" alt="">
                <div>
                  <p class="search__date">
                    {{ getFormattedDate(new Date(item.date_time)) }}
                  </p>
                  <p class="search__title">{{ GET_LANG ? item.title : item.title_eng }}</p>
                  <p class="search__text">{{ GET_LANG ? item.description : item.description_eng }}</p>
                </div>
              </div>
            </div>
          </router-link>
          <div class="wrapper-link-search"
            v-for="item in $store.getters.getStructure"
            :key="`getRegionalNews${item.id}`">
            <div>
              <documents-list :key="`list${item.id}`"
                              class="documents__list"
                              :doc="{ ...item, url: `${$store.getters.getUrlApi}${item.url}` }"
              />
            </div>
          </div>
          <router-link
            v-for="(item, i) in $store.getters.getComment"
            :to="`/search`"
            :key="`getComment${i}`">
            <div>
              <div class="search__wrapper-flex">
                <div class="tag-list">
                  <p>комментарий</p>
                </div>
                <p class="search__date">
                  {{ getFormattedDate(new Date(item.created)) }}
                </p>
              </div>
              <div class="search__wrapper-flex search-news">
                <div>
                  <p class="search__title">{{ item.title }}</p>
                  <p class="search__text">{{ item.clearText }}</p>
                </div>
              </div>
            </div>
          </router-link>
          <router-link
              v-for="(item, i) in $store.getters.getBlogs"
              :to="`/detail_blog/${item.id}?memberId=${item.member}`"
              :key="`getBlogs${i}`">
              <div>
                <div class="search__wrapper-flex">
                  <div class="tag-list">
                    <p v-for="(tag, i) in (GET_LANG ? item.tags_list : item.tags_list_eng)" :key="`item.tags_listblogs${tag}${i}`" >{{ tag }}</p>
                  </div>
                  <p class="search__date">
                    {{ getFormattedDate(new Date(item.date)) }}
                  </p>
                </div>
                <div class="search__wrapper-flex search-news">
                  <div>
                    <p class="search__title">{{ GET_LANG ? item.title : item.title_eng }}</p>
                    <p class="search__text">{{ sliceText(GET_LANG ? item.text : item.text) }}</p>
                  </div>
                </div>
              </div>
            </router-link>
        </div>
        <aside class="tag-search">
          <h3>{{ GET_LANG ? 'Искать' : 'Search' }}</h3>
          <div class="tag-search__tags">
            <span v-for="(item, i) in tagSearch"
                  :key="`tag-search${i}`"
                  @click="activeTag(item , i)"
                  :class="{ 'active-tags': item.active}"
            >{{ item.title }}</span>
          </div>
        </aside>
      </main>
    </div>
  </div>
</template>

<script>
import BreadCrumb from '@/components/BreadCrumb'
import VInputSearch from '@/components/header/VInputSearch'
import DocumentsList from '@/components/ChamberNormativeBaseDocuments'
import formatDate from '@/mixins/formatDate'
import Loader from '@/components/Loader'

export default {
  name: 'Search',
  data () {
    return {
      currentPage: 1,
      isActive: 0,
      tagSearch: []
    }
  },
  mounted () {
    this.tagSearch = [
      {
        title: this.GET_LANG ? 'везде' : 'everywhere',
        code: 'all',
        active: true
      },
      {
        title: this.GET_LANG ? 'новости палаты' : 'chamber news',
        code: 'news',
        active: false
      },
      {
        title: this.GET_LANG ? 'члены ОП РФ' : 'OP RF members',
        code: 'members',
        active: false
      },
      {
        title: this.GET_LANG ? 'блоги членов ОП РФ' : 'OP RF members blogs',
        code: 'blogs',
        active: false
      },
      {
        title: this.GET_LANG ? 'новости ОП регионов' : 'regional OP news',
        // code: 'regionalNews',
        code: 'public_chamber_news',
        active: false
      },
      // {
      //   title: 'Медиа фото',
      //   code: 'photo',
      //   active: false
      // },
      // {
      //   title: 'Медиа видео',
      //   code: 'video',
      //   active: false
      // },
      {
        title: this.GET_LANG ? 'материалы' : 'materials',
        code: 'structure',
        active: false
      },
      {
        title: this.GET_LANG ? 'актуальные комментарии' : 'actual comments',
        code: 'comment',
        active: false
      },
      {
        title: this.GET_LANG ? 'анонсы' : 'announcement',
        code: 'announcement',
        active: false
      }
    ]
  },
  watch: {
    'GET_LANG' () {
      this.tagSearch = [
        {
          title: this.GET_LANG ? 'везде' : 'everywhere',
          code: 'all',
          active: true
        },
        {
          title: this.GET_LANG ? 'новости палаты' : 'chamber news',
          code: 'news',
          active: false
        },
        {
          title: this.GET_LANG ? 'члены ОП РФ' : 'OP RF members',
          code: 'members',
          active: false
        },
        {
          title: this.GET_LANG ? 'блоги членов ОП РФ' : 'OP RF members blogs',
          code: 'blogs',
          active: false
        },
        {
          title: this.GET_LANG ? 'новости ОП регионов' : 'regional OP news',
          // code: 'regionalNews',
          code: 'public_chamber_news',
          active: false
        },
        // {
        //   title: 'Медиа фото',
        //   code: 'photo',
        //   active: false
        // },
        // {
        //   title: 'Медиа видео',
        //   code: 'video',
        //   active: false
        // },
        {
          title: this.GET_LANG ? 'материалы' : 'materials',
          code: 'structure',
          active: false
        },
        {
          title: this.GET_LANG ? 'актуальные комментарии' : 'actual comments',
          code: 'comment',
          active: false
        },
        {
          title: this.GET_LANG ? 'анонсы' : 'announcement',
          code: 'announcement',
          active: false
        }
      ]
    }
  },
  methods: {
    sliceText (text) {
      if (text.length > 250) {
        return text.slice(0, 250) + ' ...'
      } else return text
    },
    activeTag (item, i) {
      if (i !== 0) {
        this.tagSearch[0].active = false
      }
      // при нажатии на ноль все остальное должно быть false
      if (i === 0) {
        this.tagSearch.map(item => {
          item.active = false
        })
      }
      this.tagSearch[i].active = !this.tagSearch[i].active
      // если человек убрал все теги, должно выделиться все
      const checkTrue = []
      this.tagSearch.map(item => {
        if (item.active) {
          checkTrue.push(item)
        }
      })
      if (checkTrue.length === 0) {
        this.tagSearch[0].active = true
      }
    }
  },
  computed: {
    getLoader () {
      return this.$store.getters.getLoader
    },
    GET_LANG () {
      return this.$store.getters.GET_LANG
    },
    navigationLink () {
      return [
        {
          title: this.GET_LANG ? 'Главная' : 'Home',
          path: '/'
        },
        {
          title: this.GET_LANG ? 'Поиск' : 'Search'
        }
      ]
    }
  },
  mixins: [
    formatDate
  ],
  components: {
    Loader,
    BreadCrumb,
    VInputSearch,
    DocumentsList
  }
}
</script>

<style scoped lang="scss">
.search-wrapper {
  width: 90rem;
  margin: 0 auto;
}

.search__content{
  display: flex;
  flex-direction: column;
  position: relative;

  a, .wrapper-link-search{
    display: inline-flex;
    background: #FFFFFF;
    border: 1px solid #EBF3FF;
    box-sizing: border-box;
    box-shadow: 0px 15px 25px rgba(189, 205, 221, 0.35);
    border-radius: 8px;
    padding: 1.5rem;
    color: #1F3245;
    margin-bottom: 1.5rem;
    width: 100%;
  }
}

.tag-list{
  display: flex;
  flex-wrap: wrap;
  p {
    background: #EEF4FA;
    border-radius: 3px;
    padding: 0.12rem 0.65rem;
    font-weight: 600;
    font-size: 0.81rem;
    color: #003E78;
    opacity: 0.8;
    margin-bottom: 1.5rem;
    margin-right: .3rem;
  }
}

.search-news{
  .search__date {
    margin-bottom: 1rem;
  }
}

.search {
  display: flex;
  flex-wrap: wrap;

  &__title {
    font-weight: bold;
    font-size: 1rem;
    color: #1F3245;
    margin-bottom: 1.25rem;
  }
  &__date {
    font-size: 0.77rem;
    color: #5E686C;
    opacity: 0.8;
  }

  &__subtitle {
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 167%;
    color: #5E686C;
    opacity: 0.6;
    margin-bottom: 1.25rem;
    width: 100%;
  }
  &__text {
    background: #F1F4FA;
    border-radius: 8px;
    font-size: .87rem;
    line-height: 171%;
    color: #5E686C;
    padding: 0.75rem 2.93rem;
  }

  &__input {
    width: 100%;
    margin-bottom: 1.875rem;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    width: 55.375rem;

    div {
      width: 100%;
      cursor: pointer;
    }
  }

  &__tags {
    width: 26.625rem;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    margin-left: 2rem;
  }
  &__photo {
    width: 4rem;
    margin-right: 2rem;
  }
  &__img {
    width: 12rem;
    margin-right: 2rem;
  }
  &__wrapper-flex {
    display: flex;
    align-items: center;
  }

  &__tag {
    font-weight: 600;
    font-size: 0.8125rem;
    line-height: 154%;
    padding: 0.625rem 0.75rem;
    border-radius: 0.25rem;
    color: #246CB7;
    background: #EEF4FA;
    margin-right: 0.75rem;
    margin-bottom: 1rem;
    cursor: pointer;
  }

  main{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .active-tag {
    background: #246CB7;
    color: #FFFFFF;
  }
  .tag-search{
    width: 23.75rem;
    h3 {
      font-weight: bold;
      font-size: 1.12rem;
      color: rgba(23, 23, 23, 0.8);
      margin-bottom: 1.25rem;
    }

    &__tags {
      display: flex;
      flex-wrap: wrap;
      span {
        font-weight: 600;
        font-size: 0.81rem;
        line-height: 154%;
        color: #246CB7;
        padding: 0.46rem 0.75rem;
        background: #EEF4FA;
        margin-right: .75rem;
        margin-bottom: 1.06rem;
        border-radius: 4px;
        cursor: pointer;
      }
    }
  }
  .active-tags {
    color: #fff !important;
    background: #246CB7 !important;
  }
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

@media screen and (max-width: 768px) {

  .search {
    display: flex;

    &__content {
      width: 100%;

      div {
        margin-right: 0;
      }
    }

    &__input {
      order: 1;
    }

    &__subtitle {
      order: 2;
    }

    &__tags {
      width: 100%;
      margin-left: 0;
      margin-bottom: 1rem;
      order: 3;
    }

    main {
      order: 4;
    }
    .tag-search{
      width: 22.75rem;
      margin-left: 1.5rem;
    }
  }
}

@media screen and (max-width: 420px) {
  .search {
    main{
      flex-direction: column-reverse;
    }
    .tag-search{
      width: 100%;
      margin-left: 0;

      &__tags span{
        margin-right: .55rem;
      }
    }
  }
  .tag-list{
    flex-wrap: wrap;
  }
  .search-news{
    flex-direction: column;
    align-items: flex-start;
  }
  .search__img{
    width: 100%;
    margin-bottom: 1rem;
    margin-right: 0;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  main {
    display: flex !important;
  }
}

</style>
